

<template>
  <div class="rightLink"> 
    <div class="bottomDiv">
      <div class="title">查询充电站相关设备信息</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="选择省份:">
                <el-select v-model="FormData.province" placeholder="请选择省" @change="handleProvinceChange">
                  <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label" :label="provinces.label"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="选择市:">
                <el-select v-model="FormData.city" placeholder="请选择市"  @change="handleCityChange">
                  <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label }}</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="选择区/县:">
                <el-select v-model="FormData.county" placeholder="请选择区/县" >
                  <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{ countys.label }}</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="站点名称:">
                <el-input
                  v-model="FormData.station_name"
                  placeholder="请输入站点名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="充电站id:">
                <el-input
                  v-model="FormData.station_number"
                  placeholder="请输入充电站id"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="车型:">
                <el-select v-model="FormData.type_id" placeholder="请选择充电类型">
                  <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="title">充电站信息</div>
      <div class="tableDiv">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        border 
        v-loading="loading"
        style="
          width: 90%;
          margin: 20px auto;
          height: 120px;
          overflow-y: auto;
        "
      >
          <el-table-column label="充电站id" prop="station_number" />
          <el-table-column label="充电站名称" prop="station_name" />
          <!-- <el-table-column label="站点类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="充电站地址" prop="station_addr" /> -->
          <el-table-column label="省" prop="province" />
          <el-table-column label="市" prop="city" />
          <el-table-column label="县" prop="price_id" />
          <el-table-column label="社区" prop="community" />
          <el-table-column label="功率" prop="power" />
          <el-table-column label="枪头总数" prop="plug" />
          <el-table-column
            label="操作"
            width="90"
            align="center"
          >
            <template #default="scope">
              <div
                class="BtnSearch1 BtnSearch"
                @click="detail(scope.$index, scope.row)"
                >详情</div
              >
            </template>
          </el-table-column>
        </el-table>
        
      </div>
      
      <el-dialog
          v-model="Dialog.editLevy"
          width="80%"
          :before-close="closeDialog"
          class="dialog"
        >
        <div class="title">设备信息</div>
      <div class="tableDiv">
      <el-table
        ref="multipleTableRef"
        :data="tableData1"
        border 
        v-loading="loading"
        style="
          width: 90%;
          margin: 20px auto;
          height: 480px;
          overflow-y: auto;
        "
      >
          <el-table-column label="设备id" prop="id" />
          <el-table-column label="设备名称" prop="station_name" />
          <el-table-column label="设备编号" prop="device_id" />
          <el-table-column label="省" prop="province" />
          <el-table-column label="市" prop="city" />
          <el-table-column label="县" prop="county" />
          <el-table-column label="社区" prop="community" />
          <!-- <el-table-column label="充电类型" prop="contact_way" />
          <el-table-column label="枪头总数" prop="wx_id" />
          <el-table-column label="功率" prop="mailbox" /> -->
          <!-- <el-table-column label="充电枪id" prop="mailbox" /> -->
          <el-table-column label="创建时间" prop="create_time" />
          <el-table-column label="充电站名称" prop="station_name" />
          <el-table-column label="计费模板id" prop="price_id" />
        </el-table>
        <div class="pagination">
        <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
          <!-- <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog">提交</el-button>
            </span>
          </template> -->
        </el-dialog>
        </div>
      
    <!-- </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_station_all_info } from "@/request/api";
import { ElMessage } from "element-plus";
import {  regionData } from 'element-china-area-data'
export default {
  name: "SearchMan",
  setup() {
    const data = reactive({
      carList:[
          {
            value:2,
            label:'电动车'
          },
          {
            value:4,
            label:'电动汽车'
          }
        ],
        car:'',
      FormData: {
      //  username:localStorage.getItem('username')
      page:1
      },
      tableData: [
       
      ],
      tableData1: [
       
      ],
      
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      loading: false,

      options: regionData,
      selectedOptions: "",
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: '',
      city: '',
      county: '',
      
    });
    const searchBtn = () => {
      getList();
    };
   
    const getList = () => {
        const dataa = data.FormData
        data.loading = true;
        sel_station_all_info(dataa).then((res) => {
          if (res) {
            data.loading = false;
            if (res.code == 200) {
              data.tableData = res.station_dict;
              data.tableData1 = res.device_dict;
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        });
    };

    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const detail = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
  
    };



    const initProvinceList =()=> {
      
      // 初始化省份列表数据源    
      regionData.forEach((tab) => {
            data.provinceList.push(tab)
          });

    }
    const handleProvinceChange = (val) =>{
      data.cityList=[]
      data.countyList=[]
      data.province = val
      data.FormData.city = ''
      data.FormData.county = ''
       initCityList()
       
    }

    
    const initCityList=()=> {
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1)=>{
        if(tab1.label==data.province){
          data.cityList.push(tab1.children)
        }
      })
  }

  const handleCityChange=(value1)=>{
    data.countyList=[]
    data.city = value1
    data.FormData.county = ''
       initCountyList()
    }

    const initCountyList=()=> {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2)=>{
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if(tab2.label==data.city){
          data.countyList.push(tab2.children)
        }
      })
    }


    const handleCurrentChange = (val)=>{
      data.FormData.page = val;
      getList()
    }


    onMounted(() => {
      // getList();
      initProvinceList()
    });

    return {
      ...toRefs(data),
      searchBtn,
      getList,
      detail,
      closeDialog,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      handleCurrentChange
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 10px;
  height: 120px;
}
.bottomDiv{
  height: 100%;
}
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
  width: 100%;
}

</style>